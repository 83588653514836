<template>
    <div class="index">
        <!-- 背景 -->
        <div class="index_video">
            <video
                id="video"
                preload="auto"
                x5-video-player-fullscreen="true"
                x5-playsinline
                playsinline
                webkit-playsinline
                poster="../../assets/imgs/img-wechat-home-bg.png"
                autoplay
                loop
                muted
            >
                <source
                    src="https://cloud-static.skiffenergy.com/20230404/login_bg.mp4"
                    type="video/mp4"
                />
            </video>
            <div class="index_bg">
                <the-nav></the-nav>
                <div class="index_bg_bottom">
                    <h1>开启储能行业数智化时代</h1>
                    <div class="yuyue_btn" @click="booking">预约体验</div>
                </div>
            </div>
        </div>

        <!-- 项目 -->
        <div class="index_box bg_project">
            <div class="index_box_project">
                <!-- 标签tab -->
                <div class="project_tabs">
                    <div
                        class="project_tabs_item"
                        v-for="(item, index) in projectList"
                        :key="'project_' + index"
                        :class="[currentProItem === index && 'active_tab']"
                        @click="currentProItem = index"
                    >
                        {{ item.name }}
                    </div>
                </div>
                <!-- 展示图片 -->
                <img
                    class="project_img"
                    :src="projectList[currentProItem].img"
                    :alt="projectList[currentProItem].name"
                />
                <!-- 详细描述 -->
                <div class="project_description">
                    <div class="project_description_title">
                        {{ projectList[currentProItem].name }}
                    </div>
                    <div class="project_description_content">
                        {{ projectList[currentProItem].description }}
                    </div>
                    <div
                        class="project_description_btn"
                        @click="
                            $router.push(`${projectList[currentProItem].value}`)
                        "
                    >
                        查看详情
                        <svg aria-hidden="true">
                            <use xlink:href="#arrowright"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <!-- 客户 -->
        <div class="index_box bg_client">
            <div class="index_box_client">
                <div class="index_box_title">
                    <h3 class="cn_title">客户与合作伙伴</h3>
                    <h4 class="en_title">Customers and partners</h4>
                </div>
                <div class="index_box_client_list">
                    <div
                        class="index_box_client_list_item"
                        v-for="(item, index) in clientList"
                        :key="'client' + index"
                    >
                        <div
                            class="client_logo"
                            :style="{
                                'background-image':
                                    'url(' + clientList[index].url + ')',
                            }"
                        ></div>
                        <div class="client_name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 新闻 -->
        <div class="index_box bg_news" v-show="newsList.length">
            <div class="index_box_news">
                <div class="index_box_title">
                    <h3 class="cn_title">新闻动态</h3>
                    <h4 class="en_title">News</h4>
                    <div
                        class="news_more"
                        @click="$router.push({ name: 'news' })"
                    >
                        更多详情
                        <svg class="more" aria-hidden="true">
                            <use xlink:href="#arrowright"></use>
                        </svg>
                    </div>
                </div>
                <div class="news_list">
                    <div
                        class="news_list_item"
                        v-for="(item, index) in newsList"
                        :key="'news' + index"
                        @click="
                            $router.push({
                                name: 'newsDetail',
                                query: { id: item.id },
                            })
                        "
                    >
                        <img
                            class="news_list_item_img"
                            :src="item.coverImg"
                            :alt="item.title"
                            srcset=""
                        />
                        <div class="news_list_item_right">
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="time">{{ item.date }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- footer -->
        <the-footer></the-footer>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            // 项目
            currentProItem: 0,
            projectList: [
                {
                    name: "轻EMS",
                    value: "/EMS",
                    description:
                        "专为用户侧储能电站设计的能量管理系统，摒弃传统EMS的庞杂繁琐，打造简约稳定，高效智能的站控系统。",
                    img: require("@/assets/imgs/img-product-a.png"),
                },
                {
                    name: "储能管家",
                    value: "/energyguard",
                    description:
                        "储能电站全生命周期运营维护管理平台，运行监控，故障告警，运维管理，费用核算一站式全部搞定，构建多电站统筹管理数字资产平台。 ",
                    img: require("@/assets/imgs/img-product-b.png"),
                },
                {
                    name: "储电医生",
                    value: "/doctor",
                    description:
                        "智能诊断储能电池常见异常类型，提供运维指导，赋能运维人员及时保养管理电池，保障电站性能，延长电站寿命，降低失控风险。",
                    img: require("@/assets/imgs/img-product-c.png"),
                },
            ],
            // 客户
            clientList: [
                {
                    name: "中国铁塔",
                    url: require("@/assets/imgs/img-logo-customer-a.png"),
                },
                {
                    name: "EDF",
                    url: require("@/assets/imgs/img-logo-customer-b.png"),
                },
                {
                    name: "Brightree",
                    url: require("@/assets/imgs/img-logo-customer-c.png"),
                },
                {
                    name: "华东电力设计",
                    url: require("@/assets/imgs/img-logo-customer-d.png"),
                },
                {
                    name: "绿色国网",
                    url: require("@/assets/imgs/img-logo-customer-e.png"),
                },
                {
                    name: "浙江国网",
                    url: require("@/assets/imgs/img-logo-customer-e.png"),
                },
                {
                    name: "阿里云",
                    url: require("@/assets/imgs/img-logo-customer-f.png"),
                },
                {
                    name: "腾讯综能工场",
                    url: require("@/assets/imgs/img-logo-customer-g.png"),
                },
            ],
            // news
            newsList: [],
        };
    },
    mounted() {
        this.getNewsList();
    },
    beforeDestroy() {
        document.removeEventListener("scroll", this.scrollAnimation);
    },
    methods: {
        // ios video自动播放
        iosAuto() {},
        // 预约modal
        booking() {
            this.$store.commit("setState", { visible: true });
        },
        // 项目 滚动展示
        scrollAnimation() {
            let container = document.querySelector(".bg_project");
            window.addEventListener("scroll", () => {
                let index =
                    (document.documentElement.scrollTop - container.offsetTop) /
                    (container.scrollHeight - container.offsetTop);
                let step = 1 / this.projectList.length;
                this.currentProItem =
                    Math.floor(index / step) >= 2
                        ? 2
                        : Math.floor(index / step) <= 0
                        ? 0
                        : Math.floor(index / step);
            });
        },
        // 获取新闻列表
        getNewsList() {
            this.$getJSON("/api/news/list", { method: "GET" }).then((res) => {
                this.newsList = res.data.slice(0, 4);
                localStorage.setItem("newsList", JSON.stringify(this.newsList));
            });
        },
    },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
